import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useLiff } from 'react-liff';
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../components/Login";
import LiffLogin from "../../components/LiffLogin";
import { PATH } from "../../routes/CustomRoutes";
import { Dimmer, Loader, Message, TextArea } from "semantic-ui-react";


export default function LiffLoginScreen(props) {
  const navigate = useNavigate();
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const  { app } = useParams();
  const [nextPath, setNextPath] = useState(PATH.LIFF_PROFILE)
  const [errorTxt, setErrorTxt] = useState("")

  useEffect(() => {
    setNextPath(`/liff-app/${app}`);  // Manual set next route from given URL
  }, [app])

  useEffect(() => {
    if (isReady && Cookies.get('token') != null) {
      // Already logged in, auto redirect
      navigate(nextPath);
    }
  }, [isReady])

  // useEffect(() => {
  //   setErrorTxt(`${String(error)}`)
  // }, [error])

  const handleOnLoggedIn = async (values) => {
    navigate(nextPath);
  };

  return (
    <>
      {!isReady && !error &&
        <Dimmer active>
          <Loader>เชื่อมต่อกับ LINE...</Loader>
        </Dimmer>
        // <TextArea value={errorTxt} rows={7}/>
      }
      {isReady && !isLoggedIn &&
        <div>
          <LiffLogin onClick={() => {liff.login()}}/>
        </div>
      }
      {isReady && isLoggedIn && Cookies.get('token') == null &&
        <Login onLoggedIn={handleOnLoggedIn} liff={liff}/>
      }
      {error &&
        <Message
          negative
          icon="exclamation circle"
          header="เกิดความผิดพลาดในการเชื่อมต่อกรุณาติดต่อผู้ดูแลระบบ"
          content={String(error)}
        />
      }
    </>
  )
}


import { useEffect, useState } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { GET } from '../../frameworks/HttpClient';
import { URL_MEMBER, URL_AUTH } from '../../constances/urls';
import { Button, Grid, Image, Dimmer, Loader, Header, List } from 'semantic-ui-react';
import { Toast } from "../../frameworks/Toast";


export default function LiffProfileScreen(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let response = await GET(URL_MEMBER.PROFILE);
        setProfile(response.data);
      } catch (err) { Toast.error(`เกิดข้อผิดพลาด : ${err.errorMessages}`) }
      finally{ setIsLoading(false); }
    })()
  }, [])
  
  const handleLogout = async () => {
    await GET(URL_AUTH.SIGN_OUT);
    Cookies.remove('token');
    navigate(props.isDesktop ? "/login" : "/liff/profile/");
  }

  const renderSumAmount = () => {
    if (profile.sum_amount > 0) {
      return <span style={{ 'color': 'red'}}>{profile.sum_amount}</span>
    }
    else {
      return <span style={{ 'color': 'green' }}>{profile.sum_amount}</span>
    }
  }

  const renderEventDate = () => {
    let startDate = new moment(props.event_start_date_time);
    let endDate = new moment(props.event_end_date_time);

    if (startDate.isSame(endDate, 'day')) {
      return (
        <>
          <Moment date={startDate} format="DD/MM/YYYY [HH:mm น. - " />
          <Moment date={endDate} format="HH:mm น.]" />
        </>
      )
    }
    else {
      return (
        <>
          <Moment date={startDate} format="DD/MM/YYYY - HH:mm น. ถึง " />
          <Moment date={endDate} format="DD/MM/YYYY - HH:mm น." />
        </>
      )
    }
  }

  const renderRegisteredEvent = () => (
    profile.registrations.map( reg => (
      <List.Item>
        { reg.payment_datetime != null &&
          < List.Icon name='check circle' size='large' verticalAlign='middle' color='green'/>
        }
        { reg.payment_datetime == null &&
          < List.Icon name='info circle' size='large' verticalAlign='middle' color='yellow'/>
        }
        <List.Content>
          <List.Header>{reg.registered_event.name} ({reg.price} บาท)</List.Header>
          <List.Description>{renderEventDate()}</List.Description>
        </List.Content>
      </List.Item>
    ))
  )
    

  return(
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      {isLoading &&
        <Dimmer active>
          <Loader>Loading...</Loader>
        </Dimmer>
      }
      {!isLoading && 
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column
            style={{
              maxWidth: 450,
              display: 'flex',
              alignItems: "center"
            }}>
              { profile == null &&
                <Dimmer active>
                  <Loader>Loading...</Loader>
                </Dimmer>
              }
              { profile != null && 
              <>
                <Image size='small' src={profile.liff_picture_url}/>
                <Header as='h2'>{profile.first_name} {profile.last_name}</Header>
                <Header as='h3'>ยอดเงินค้างชำระ {renderSumAmount()} บาท</Header>
                <List divided relaxed>
                  {renderRegisteredEvent()}
                </List>
                <Button color='google plus' onClick={handleLogout}>Log Out</Button>
              </>
              }
          </Grid.Column>
        </Grid>
      }
    </div>
  )
}

LiffProfileScreen.propTypes = {
  isDesktop: PropTypes.bool,
};

LiffProfileScreen.defaultProps = {
  isDesktop: false,
};